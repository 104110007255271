import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';

import ModalConfirmation from '../../../ModalConfirmation';
import AdminAssemblyDeliveryPreferenceForm from '../../../assemblies/AdminAssemblyDeliveryPreferenceForm';
import AdminAssemblyDisassemblyForm from '../../../assemblies/AdminAssemblyDisassemblyForm';
import AdminAssemblyNeedsPreferenceForm from '../../../assemblies/AdminAssemblyNeedsPreferenceForm';
import AdminAssemblyOnSiteRepresentativeForm from '../../../assemblies/AdminAssemblyOnSiteRepresentativeForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import SaveAssemblyDto from '../../../../dto/assemblies/in/save-assembly.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import DoorTypesEnum from '../../../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../../../enums/assemblies/handling-equipments.enum';
import useFindAssemblyByUserId from '../../../../hooks/assemblies/find-assembly-by-user-id.hook';
import useSaveAssembly from '../../../../hooks/assemblies/save-assembly.hook';
import AdminAssemblyFieldValues from '../../../../interfaces/assemblies/admin-assembly-field-values.interface';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';
import {
  formatTime,
  getDateFromDateAndTimeString,
} from '../../../../utils/date';

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const AdminEditAssemblyPage: FC = () => {
  const [modalOpened, toggleModal] = useToggle(false);

  const { accessOrder, assembly, booking, detailedSheet, profile } =
    useOutletContext<OutletContext>();

  const { refetch } = useFindAssemblyByUserId(profile.user.id);
  const { mutateAsync: saveAssembly } = useSaveAssembly();

  const deliveryDate = assembly.deliveryDate ?? assembly.deliveryDatePreference;

  const formMethods = useForm<AdminAssemblyFieldValues>({
    defaultValues: {
      firstName: assembly.firstName ?? '',
      lastName: assembly.lastName ?? '',
      email: assembly.email ?? '',
      phoneNumber: assembly.phoneNumber ?? '',
      handlingEquipment:
        assembly.handlingEquipment ?? HandlingEquipmentsEnum.FORKLIFT,
      doorType: assembly.doorType ?? DoorTypesEnum.GARAGE_GROUND_LEVEL,
      needsNotes: assembly.needsNotes ?? '',
      disassemblyDate: assembly.disassemblyDate ?? '',
      deliveryDate: deliveryDate ?? '',
      deliveryTime:
        assembly.deliveryDate && assembly.deliveryTime
          ? getDateFromDateAndTimeString(
              assembly.deliveryDate,
              assembly.deliveryTime,
            )
          : undefined,
      doorNumber: assembly.doorNumber ? `${assembly.doorNumber}` : '',
    },
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = plainToClass(SaveAssemblyDto, {
        firstName: fieldValues.firstName?.trim(),
        lastName: fieldValues.lastName?.trim(),
        email: fieldValues.email?.trim(),
        phoneNumber: fieldValues?.phoneNumber?.trim(),
        handlingEquipment: fieldValues.handlingEquipment,
        doorType: fieldValues.doorType,
        doorNumber: fieldValues.doorNumber,
        needsNotes: fieldValues.needsNotes?.trim(),
        deliveryDate: fieldValues.deliveryDate,
        deliveryTime: fieldValues.deliveryTime
          ? formatTime(fieldValues.deliveryTime)
          : undefined,
        disassemblyDate: fieldValues.disassemblyDate,
      });

      await saveAssembly({ id: assembly.id, dto });
      await refetch();

      toast.success('Enregistré et notifié avec succès');
    } catch (error) {
      toast.error();
    } finally {
      toggleModal(false);
    }
  }, [assembly, getValues, refetch, saveAssembly, toggleModal]);

  const onSubmit: SubmitHandler<AdminAssemblyFieldValues> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="assembly" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Représentant sur le site
            </Typography>

            <AdminAssemblyOnSiteRepresentativeForm />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Horaire de montage
            </Typography>

            <AdminAssemblyDeliveryPreferenceForm assembly={assembly} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Service de manutention
            </Typography>

            <AdminAssemblyNeedsPreferenceForm />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Démontage
            </Typography>

            <AdminAssemblyDisassemblyForm />
          </Section>

          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            sx={{ float: 'right' }}
            startIcon={isSubmitting && <ButtonLoadingIcon />}
          >
            Enregistrer et notifier
          </Button>

          <ModalConfirmation
            opened={modalOpened}
            title="Envoi de la demande"
            onSubmit={handleSave}
            onClose={(): void => toggleModal(false)}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default AdminEditAssemblyPage;
