import OptionInterface from './interfaces/option.interface';

export const ASSEMBLY_DELIVERY_DATE_KITCHEN: OptionInterface = {
  label: 'Vendredi 16 février 2024',
  value: '2024-02-16',
};

export const ASSEMBLY_DELIVERY_DATES: OptionInterface[] = [
  { label: 'Samedi 17 février 2024', value: '2024-02-17' },
  { label: 'Dimanche 18 février 2024', value: '2024-02-18' },
  { label: 'Lundi 19 février 2024', value: '2024-02-19' },
  { label: 'Mardi 20 février 2024', value: '2024-02-20' },
  { label: 'Mercredi 21 février 2024', value: '2024-02-21' },
];

export const DISASSEMBLY_DELIVERY_DATES: OptionInterface[] = [
  { label: 'Dimanche 25 février 2024 de 17h à 23h', value: '2024-02-25' },
  { label: 'Lundi 26 février 2024 de 7h à 17h', value: '2024-02-26' },
];
