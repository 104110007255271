import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetInfosSection: FC<Props> = ({ detailedSheet }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Votre présence en ligne
      </Typography>

      <FormStack>
        <FormGrid>
          {detailedSheet.websiteUrl && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Site Internet</ReadonlyFieldLabel>

              <Typography>{detailedSheet.websiteUrl}</Typography>
            </Grid>
          )}

          {detailedSheet.facebookUrl && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Facebook</ReadonlyFieldLabel>

              <Typography>{detailedSheet.facebookUrl}</Typography>
            </Grid>
          )}

          {detailedSheet.instagramUrl && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Instagram</ReadonlyFieldLabel>

              <Typography>{detailedSheet.instagramUrl}</Typography>
            </Grid>
          )}

          {detailedSheet.linkedinUrl && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Linkedin</ReadonlyFieldLabel>

              <Typography>{detailedSheet.linkedinUrl}</Typography>
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetInfosSection;
