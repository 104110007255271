import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ExhibitorDashboardCard from './ExhibitorDashboardCard';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import DetailedSheetStatusesEnum from '../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import { PATHS } from '../../utils/paths';

const { CANCELED, APPROVED, INCOMPLETE, PROCESSING } =
  DetailedSheetStatusesEnum;

interface Props {
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
}

const ExhibitorDashboardDetailedSheetCard: FC<Props> = ({
  detailedSheet,
  edition,
}) => {
  const navigate = useNavigate();

  const detailedSheetStatus = useMemo(
    () => detailedSheet?.status ?? INCOMPLETE,
    [detailedSheet],
  );

  const chipColor = useMemo(() => {
    if (!edition.detailedSheetsOpened) return 'default';

    switch (detailedSheetStatus) {
      case CANCELED: {
        return 'default';
      }
      case INCOMPLETE: {
        return 'error';
      }
      case PROCESSING: {
        return 'warning';
      }
      case APPROVED: {
        return 'success';
      }
    }
  }, [detailedSheetStatus, edition]);

  const chipLabel = useMemo(() => {
    if (!edition.detailedSheetsOpened) return 'Disponible prochainement';

    switch (detailedSheetStatus) {
      case CANCELED: {
        return 'Annulé';
      }
      case INCOMPLETE: {
        return 'Incomplet';
      }
      case PROCESSING: {
        return 'En traitement';
      }
      case APPROVED: {
        return 'Approuvé';
      }
    }
  }, [detailedSheetStatus, edition]);

  const buttonText = useMemo(
    () => (detailedSheetStatus === INCOMPLETE ? 'Compléter' : 'Consulter'),
    [detailedSheetStatus],
  );

  const buttonVariant = useMemo(
    () => (detailedSheetStatus === INCOMPLETE ? 'contained' : 'text'),
    [detailedSheetStatus],
  );

  const handleNavigation: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();

      const path =
        detailedSheetStatus === INCOMPLETE
          ? PATHS.exhibitors.detailedSheets.edit.path
          : PATHS.exhibitors.detailedSheet.path;

      navigate(path);
    },
    [detailedSheetStatus, navigate],
  );

  return (
    <ExhibitorDashboardCard>
      <CardContent>
        <Chip color={chipColor} label={chipLabel} sx={{ mb: 3 }} />

        <Typography gutterBottom variant="h3">
          Fiche exposant
        </Typography>

        <Typography variant="body2" color="text.secondary">
          La fiche exposant est une fiche descriptive de votre entreprise qui
          sera publiée dans le Guide du visiteur, format numérique, ainsi que sur
          le site Internet d’Expo habitat, dans la section «&nbsp;Liste des
          exposants&nbsp;».
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          Vous devez remplir votre fiche au plus tard le{' '}
          <strong>vendredi 22 novembre 2024.</strong>
        </Typography>
      </CardContent>

      {edition.detailedSheetsOpened && (
        <CardActions>
          <Button variant={buttonVariant} onClick={handleNavigation}>
            {buttonText}
          </Button>
        </CardActions>
      )}
    </ExhibitorDashboardCard>
  );
};

export default ExhibitorDashboardDetailedSheetCard;
