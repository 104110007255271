import { Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';

interface Props {
  max: number;
}

const CharacterCounter = <T extends FieldValues>({
  control,
  name,
  max,
}: Props & UseControllerProps<T>): ReactElement => {
  const { field } = useController({ control, name });

  const nbCharacters = useMemo(() => field.value?.length ?? 0, [field]);

  const color = useMemo(() => {
    if (nbCharacters > max) return 'error.main';

    return undefined;
  }, [max, nbCharacters]);

  return (
    <Typography
      variant="body2"
      color={color}
      sx={{ mt: 1, textAlign: 'right' }}
    >
      Nombre de caractères : {nbCharacters} / {max}
    </Typography>
  );
};

export default CharacterCounter;
