import Typography from '@mui/material/Typography';
import { FC, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import DetailedSheetCompanySection from '../../../detailed-sheets/DetailedSheetCompanySection';
import DetailedSheetDescriptionsSection from '../../../detailed-sheets/DetailedSheetDescriptionsSection';
import DetailedSheetImagesSection from '../../../detailed-sheets/DetailedSheetImagesSection';
import DetailedSheetInfosSection from '../../../detailed-sheets/DetailedSheetInfosSection';
import SectionHeader from '../../../layouts/SectionHeader';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';

interface OutletContext {
  profile: ProfileDto;
  booking: BookingDto;
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetPage: FC = () => {
  const { booking, detailedSheet, profile } = useOutletContext<OutletContext>();

  const hasDetailedSheetInfos = useMemo(
    () =>
      detailedSheet.websiteUrl ||
      detailedSheet.facebookUrl ||
      detailedSheet.instagramUrl ||
      detailedSheet.linkedinUrl,
    [detailedSheet],
  );

  return (
    <>
      <SectionHeader>
        <Typography variant="h1" gutterBottom>
          Fiche exposant
        </Typography>
      </SectionHeader>

      <DetailedSheetCompanySection
        profile={profile}
        booking={booking}
        detailedSheet={detailedSheet}
      />

      {hasDetailedSheetInfos && (
        <DetailedSheetInfosSection detailedSheet={detailedSheet} />
      )}

      <DetailedSheetDescriptionsSection detailedSheet={detailedSheet} />

      <DetailedSheetImagesSection detailedSheet={detailedSheet} />
    </>
  );
};

export default DetailedSheetPage;
