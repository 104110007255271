import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import VerifyEditionClosedService from '../../services/editions/verify-edition-closed.service';

const useEditionClosed = (
  options?: Omit<UseQueryOptions<boolean, Error>, 'queryKey' | 'queryFn'>,
): UseQueryResult<boolean, Error> => {
  return useQuery(
    ['/editions/inactive'],
    new VerifyEditionClosedService().verify,
    options,
  );
};

export default useEditionClosed;
