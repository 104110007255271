import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FileUpload from './FileUpload';
import EditionTabs from '../../../editions/EditionTabs';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import { PATHS } from '../../../../utils/paths';

const FilesPage: FC = () => {
  return (
    <>
      <SectionHeader>
        <Typography variant="h2">Édition</Typography>
      </SectionHeader>

      <EditionTabs value="documents" />

      <Section>
        <FormStack>
          <FormGrid>
            <Grid xs={12} item>
              <Typography variant="overline" component="h2">
                Documents
              </Typography>
            </Grid>

            <FileUpload
              fileName="plan.pdf"
              label="Plan du Salon"
              url={PATHS.documents.plan.path}
            />

            <FileUpload
              fileName="echeancier-priorites-location.pdf"
              label="Échéancier des priorités"
              url={PATHS.documents.timetable.path}
            />

            <FileUpload
              fileName="guide-exposant.pdf"
              label="Guide de l'exposant"
              url={PATHS.documents.guide.path}
            />

            <FileUpload
              fileName="allee-des-immeubles-locatifs.pdf"
              label="Allée des immeubles locatifs"
              url={PATHS.documents.rentalProperties.path}
            />

            <FileUpload
              fileName="politique-pieds-carres.pdf"
              label="Politique Pieds carrés par secteur d'activité"
              url={PATHS.documents.squareFeetPolicy.path}
            />

            <FileUpload
              fileName="faq.pdf"
              label="FAQ"
              url={PATHS.documents.faq.path}
            />

            <FileUpload
              fileName="contrat.pdf"
              label="Contrat"
              url={PATHS.documents.contract.path}
            />

            <FileUpload
              fileName="liste-prix-visibilite.pdf"
              label="Liste de prix Visibilité"
              url={PATHS.documents.visibilityRates.path}
            />

            <FileUpload
              fileName="bon-commande-tse.pdf"
              label="Bon de commande - TSE"
              url={PATHS.documents.purchaseOrderTSE.path}
            />
          </FormGrid>
        </FormStack>
      </Section>
    </>
  );
};

export default FilesPage;
