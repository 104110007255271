import Grid from '@mui/material/Grid';
import bytes from 'bytes';
import { FC } from 'react';
import { Control } from 'react-hook-form';

import Dropzone from '../forms/Dropzone';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { validateRequired } from '../../utils/validations';

const ACCEPT = { 'image/gif': [], 'image/jpeg': [], 'image/png': [] };
const MIN_IMAGE_SIZE = bytes('0Mb');
const MAX_IMAGE_SIZE = bytes('2Mb');

interface Props {
  control: Control<DetailedSheetFieldValuesInterface>;
}

const DetailedSheetImagesForm: FC<Props> = ({ control }) => {
  return (
    <FormStack>
      <FormGrid>
        <Grid item>
          <Dropzone
            control={control}
            name="logo"
            label="Logo"
            description="Le logo de votre compagnie sur fond blanc ou transparent. Le fichier ne doit pas excéder 2mo et doit être au format jpg, png ou gif."
            rules={validateRequired()}
            options={{
              accept: ACCEPT,
              minSize: MIN_IMAGE_SIZE,
              maxSize: MAX_IMAGE_SIZE,
              multiple: false,
            }}
          />

          <Dropzone
            control={control}
            name="image"
            label="Image (facultatif)"
            description="Une image supplémentaire que nous pourrons afficher sur le site Web et qui représente l'activité de votre compagnie. Elle ne doit pas excéder 2mo et doit être au format jpg, png ou gif."
            options={{
              accept: ACCEPT,
              minSize: MIN_IMAGE_SIZE,
              maxSize: MAX_IMAGE_SIZE,
              multiple: false,
            }}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default DetailedSheetImagesForm;
