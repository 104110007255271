import { apiV1 } from '../../http';

class VerifyEditionClosedService {
  async verify(): Promise<boolean> {
    const { data } = await apiV1.get<boolean>('/editions/closed');

    return data;
  }
}

export default VerifyEditionClosedService;
