import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Select from '../forms/Select';
import TimePicker from '../forms/TimePicker';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import OptionInterface from '../../interfaces/option.interface';
import AdminAssemblyFieldValues from '../../interfaces/assemblies/admin-assembly-field-values.interface';
import { formatDateLong, getDatePeriodLabel } from '../../utils/date';
import { validateRequired } from '../../utils/validations';
import {
  ASSEMBLY_DELIVERY_DATES,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
} from '../../values';

const DELIVERY_DATE_OPTIONS: OptionInterface[] = [
  { label: 'Sélectionner', value: '' },
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
  ...ASSEMBLY_DELIVERY_DATES,
];

interface Props {
  assembly: AssemblyDto;
}

const AdminAssemblyDeliveryPreferenceForm: FC<Props> = ({ assembly }) => {
  const { control } = useFormContext<AdminAssemblyFieldValues>();

  const period = useMemo(() => {
    if (!assembly.deliveryDatePeriodPreference) return null;

    return getDatePeriodLabel(assembly.deliveryDatePeriodPreference);
  }, [assembly]);

  const renderDeliveryDatePreference = useMemo(() => {
    if (!assembly.deliveryDatePreference) return null;

    const date = formatDateLong(parseISO(assembly.deliveryDatePreference));

    return (
      <Grid item xs={12}>
        <ReadonlyFieldLabel>Journée et période désirées</ReadonlyFieldLabel>

        <Typography>
          {date}, {period}
        </Typography>
      </Grid>
    );
  }, [assembly, period]);

  return (
    <FormStack>
      <FormGrid>
        {renderDeliveryDatePreference}

        <Grid item xs={12} md={4}>
          <Select
            control={control}
            name="deliveryDate"
            label="Confirmation de la journée"
            options={DELIVERY_DATE_OPTIONS}
            rules={validateRequired()}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TimePicker
            control={control}
            name="deliveryTime"
            label="Confirmation de l'heure"
            rules={validateRequired()}
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminAssemblyDeliveryPreferenceForm;
