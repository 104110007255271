import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useToggle } from '@react-hookz/web';

import ModalConfirmation from '../../../ModalConfirmation';
import AssemblyDeliveryPreferenceForm from '../../../assemblies/AssemblyDeliveryPreferenceForm';
import AssemblyDisassemblyForm from '../../../assemblies/AssemblyDisassemblyForm';
import AssemblyNeedsPreferenceForm from '../../../assemblies/AssemblyNeedsPreferenceForm';
import AssemblyOnSiteRepresentativeForm from '../../../assemblies/AssemblyOnSiteRepresentativeForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import SaveUserAssemblyDto from '../../../../dto/assemblies/in/save-user-assembly.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import DatePeriodsEnum from '../../../../enums/date-periods.enum';
import DoorTypesEnum from '../../../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../../../enums/assemblies/handling-equipments.enum';
import useSaveUserAssembly from '../../../../hooks/assemblies/save-user-assembly.hook';
import AssemblyFieldValues from '../../../../interfaces/assemblies/assembly-field-values.interface';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

interface OutletContext {
  assembly: AssemblyDto;
  profile: ProfileDto;
}

const EditAssemblyPage: FC = () => {
  const navigate = useNavigate();

  const [modalOpened, toggleModal] = useToggle(false);

  const { assembly, profile } = useOutletContext<OutletContext>();

  const { mutateAsync: saveUserAssembly } = useSaveUserAssembly();

  const formMethods = useForm<AssemblyFieldValues>({
    defaultValues: {
      firstName: assembly.firstName ?? '',
      lastName: assembly.lastName ?? '',
      email: assembly.email ?? '',
      phoneNumber: assembly.phoneNumber ?? '',
      deliveryDatePreference: assembly.deliveryDatePreference ?? '',
      deliveryDatePeriodPreference:
        assembly.deliveryDatePeriodPreference ?? DatePeriodsEnum.AM,
      handlingEquipment:
        assembly.handlingEquipment ?? HandlingEquipmentsEnum.FORKLIFT,
      doorType: assembly.doorType ?? DoorTypesEnum.GARAGE_GROUND_LEVEL,
      needsNotes: assembly.needsNotes ?? '',
      disassemblyDate: assembly.disassemblyDate ?? '',
    },
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = plainToClass(SaveUserAssemblyDto, {
        firstName: fieldValues.firstName?.trim(),
        lastName: fieldValues.lastName?.trim(),
        email: fieldValues.email?.trim(),
        phoneNumber: fieldValues?.phoneNumber?.trim(),
        deliveryDatePreference: fieldValues.deliveryDatePreference,
        deliveryDatePeriodPreference: fieldValues.deliveryDatePeriodPreference,
        handlingEquipment: fieldValues.handlingEquipment,
        doorType: fieldValues.doorType,
        needsNotes: fieldValues.needsNotes?.trim(),
        disassemblyDate: fieldValues.disassemblyDate,
      });

      await saveUserAssembly(dto);

      toast.success('Enregistré avec succès');

      navigate(PATHS.exhibitors.path);
    } catch (error) {
      toast.error();
    } finally {
      toggleModal(false);
    }
  }, [getValues, navigate, saveUserAssembly, toggleModal]);

  const onSubmit: SubmitHandler<AssemblyFieldValues> = useCallback(async () => {
    toggleModal(true);
  }, [toggleModal]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <SectionHeader>
          <Typography variant="h1" gutterBottom>
            Montage et démontage
          </Typography>
        </SectionHeader>

        <Alert severity="warning">
          <AlertTitle>Information importante</AlertTitle>
          Une fois soumis, il ne vous sera plus possible de modifier le
          formulaire manuellement. Vous devrez communiquer avec Jessica Légaré
          au{' '}
          <Link href="mailto:jlegare@apchqquebec.ca" underline="none">
            jlegare@apchqquebec.ca
          </Link>{' '}
          ou au 418-682-3353 pour effectuer une modification.
        </Alert>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Représentant sur le site
          </Typography>

          <AssemblyOnSiteRepresentativeForm />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Horaire de montage
          </Typography>

          <AssemblyDeliveryPreferenceForm profile={profile} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Service de manutention
          </Typography>

          <AssemblyNeedsPreferenceForm />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Démontage
          </Typography>

          <AssemblyDisassemblyForm />
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer et envoyer
        </Button>

        <ModalConfirmation
          opened={modalOpened}
          title="Voulez-vous vraiment envoyer la demande ?"
          text="Votre demande sera officiellement envoyée pour traitement. Une fois soumis, il ne vous sera plus possible de modifier le formulaire manuellement. "
          onSubmit={handleSave}
          onClose={(): void => toggleModal(false)}
        />
      </form>
    </FormProvider>
  );
};

export default EditAssemblyPage;
