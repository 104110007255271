import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import AdminDetailedSheetCompanyForm from '../../../detailed-sheets/AdminDetailedSheetCompanyForm';
import AdminDetailedSheetDescriptionsForm from '../../../detailed-sheets/AdminDetailedSheetDescriptionsForm';
import AdminDetailedSheetInfosForm from '../../../detailed-sheets/AdminDetailedSheetInfosForm';
import AdminDetailedSheetImagesForm from '../../../detailed-sheets/AdminDetailedSheetImagesForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import SaveDetailedSheetDto from '../../../../dto/detailed-sheets/in/save-detailed-sheet.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import SaveFileDto from '../../../../dto/files/in/save-file.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import DetailedSheetStatusesEnum from '../../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import DetailedSheetFieldValuesInterface from '../../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import useFindDetailedSheetByUserId from '../../../../hooks/detailed-sheets/find-detailed-sheet-by-user-id.hook';
import useApproveDetailedSheet from '../../../../hooks/detailed-sheets/approve-detailed-sheet.hook';
import useSaveDetailedSheet from '../../../../hooks/detailed-sheets/save-detailed-sheet.hook';
import useUnapproveDetailedSheet from '../../../../hooks/detailed-sheets/unapprove-detailed-sheet.hook';
import MapDetailedSheetImageToSaveFileDtoService from '../../../../services/detailed-sheets/map-detailed-sheet-image-to-save-file-dto.service';
import UploadDetailedSheetImagesService from '../../../../services/detailed-sheets/upload-detailed-sheet-images.service';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const mapDetailedSheetImageToSaveFileDtoService =
  new MapDetailedSheetImageToSaveFileDtoService();

const { APPROVED, PROCESSING } = DetailedSheetStatusesEnum;

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
  profile: ProfileDto;
}

const AdminEditDetailedSheetPage: FC = () => {
  const { accessOrder, assembly, booking, detailedSheet, edition, profile } =
    useOutletContext<OutletContext>();

  const [approved, setApproved] = useState(detailedSheet.status === APPROVED);

  const userId = profile.user.id;

  const queryClient = useQueryClient();
  const { refetch } = useFindDetailedSheetByUserId(userId);
  const { mutateAsync: approveDetailedSheet } = useApproveDetailedSheet();
  const { mutateAsync: unapproveDetailedSheet } = useUnapproveDetailedSheet();
  const { mutateAsync: saveDetailedSheet } = useSaveDetailedSheet();

  const formMethods = useForm<DetailedSheetFieldValuesInterface>({
    defaultValues: {
      companyName: detailedSheet.companyName,
      address: detailedSheet.address,
      city: detailedSheet.city,
      province: detailedSheet.province,
      postalCode: detailedSheet.postalCode,
      email: detailedSheet.email,
      phoneNumber: detailedSheet.phoneNumber,
      phoneNumberExt: detailedSheet.phoneNumberExt ?? '',
      isRbqMember: `${!!detailedSheet.rbqMemberNumber}`,
      rbqMemberNumber: detailedSheet.rbqMemberNumber ?? '',
      websiteUrl: detailedSheet.websiteUrl ?? '',
      instagramUrl: detailedSheet.instagramUrl ?? '',
      facebookUrl: detailedSheet.facebookUrl ?? '',
      linkedinUrl: detailedSheet.linkedinUrl ?? '',
      websiteDescription: detailedSheet.websiteDescription,
      printDescription: detailedSheet.printDescription,
      tags: detailedSheet.tags,
      logo: detailedSheet.logo
        ? new File([], detailedSheet.logo.name)
        : undefined,
      image: detailedSheet.image
        ? new File([], detailedSheet.image.name)
        : undefined,
    },
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    getFieldState,
    setValue,
  } = formMethods;

  const onSubmit: SubmitHandler<DetailedSheetFieldValuesInterface> =
    useCallback(
      async ({ logo, image, ...fieldValues }) => {
        try {
          const uploads: [File, SaveFileDto][] = [];

          let [saveLogoDto, saveImageDto] = [
            detailedSheet.logo,
            detailedSheet.image,
          ].map((detailedSheetFile) =>
            detailedSheetFile
              ? plainToClass(SaveFileDto, {
                  id: detailedSheetFile.id,
                  key: detailedSheetFile.key,
                  name: detailedSheetFile.name,
                  extension: detailedSheetFile.extension,
                  contentType: detailedSheetFile.contentType,
                  size: detailedSheetFile.size,
                  originalName: detailedSheetFile.originalName,
                })
              : undefined,
          );

          [
            { file: logo, name: 'logo' },
            { file: image, name: 'image' },
          ].forEach(({ file, name }) => {
            const isDirty = getFieldState(name as 'logo' | 'image').isDirty;

            if (file && isDirty) {
              const saveFileDto = mapDetailedSheetImageToSaveFileDtoService.map(
                edition,
                profile,
                file,
                name,
              );

              let dto: SaveFileDto;

              name === 'logo'
                ? (dto = saveLogoDto = saveFileDto)
                : (dto = saveImageDto = saveFileDto);

              uploads.push([file, dto]);
            }
          });

          if (uploads.length) {
            await new UploadDetailedSheetImagesService().upload(uploads);
          }

          const dto = plainToClass(SaveDetailedSheetDto, {
            id: detailedSheet.id,
            companyName: fieldValues.companyName.trim(),
            address: fieldValues.address,
            city: fieldValues.city,
            province: fieldValues.province,
            postalCode: fieldValues.postalCode,
            email: fieldValues.email?.trim(),
            phoneNumber: fieldValues.phoneNumber?.trim(),
            phoneNumberExt: fieldValues.phoneNumberExt,
            rbqMemberNumber: fieldValues.rbqMemberNumber,
            websiteUrl: fieldValues.websiteUrl,
            instagramUrl: fieldValues.instagramUrl,
            facebookUrl: fieldValues.facebookUrl,
            linkedinUrl: fieldValues.linkedinUrl,
            websiteDescription: fieldValues.websiteDescription.trim(),
            printDescription: fieldValues.printDescription.trim(),
            tags: fieldValues.tags.trim(),
            logo: plainToClass(SaveFileDto, {
              ...saveLogoDto,
              id: detailedSheet.logo?.id,
            }),
            image:
              image &&
              plainToClass(SaveFileDto, {
                ...saveImageDto,
                id: detailedSheet.image?.id,
              }),
            userId,
          });

          await saveDetailedSheet(dto);
          await refetch();

          toast.success('Enregistré avec succès');
        } catch (error) {
          toast.error();
        }
      },
      [
        detailedSheet,
        edition,
        profile,
        userId,
        getFieldState,
        refetch,
        saveDetailedSheet,
      ],
    );

  const handleApprove = useCallback(async () => {
    try {
      let method = unapproveDetailedSheet;
      let toastMessage = 'Approbation retirée avec succès';
      let nextApprovedState = false;
      let status = PROCESSING;

      if (!approved) {
        method = approveDetailedSheet;
        toastMessage = 'Approuvé avec succès';
        nextApprovedState = true;
        status = APPROVED;
      }

      await method({ id: detailedSheet.id });

      queryClient.setQueryData<DetailedSheetDto | undefined>(
        ['users/detailed-sheet', userId],
        (detailedSheet) => {
          if (detailedSheet) {
            return {
              ...detailedSheet,
              status,
            };
          }
        },
      );

      setApproved(nextApprovedState);

      toast.success(toastMessage);
    } catch (error) {
      toast.error();
    }
  }, [
    detailedSheet,
    approved,
    queryClient,
    userId,
    approveDetailedSheet,
    setApproved,
    unapproveDetailedSheet,
  ]);

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="detailed-sheet" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Entreprise
            </Typography>

            <AdminDetailedSheetCompanyForm
              control={control}
              booking={booking}
              profile={profile}
              setValue={setValue}
            />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Votre présence en ligne
            </Typography>

            <AdminDetailedSheetInfosForm control={control} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Descriptions
            </Typography>

            <AdminDetailedSheetDescriptionsForm control={control} />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Images
            </Typography>

            <AdminDetailedSheetImagesForm
              control={control}
              detailedSheet={detailedSheet}
            />
          </Section>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControlLabel
              sx={{ mr: 8 }}
              control={
                <Switch
                  defaultChecked={approved}
                  value={approved}
                  onChange={handleApprove}
                />
              }
              label="Approuvé"
            />

            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ float: 'right' }}
              startIcon={isSubmitting && <ButtonLoadingIcon />}
            >
              Enregistrer
            </Button>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default AdminEditDetailedSheetPage;
