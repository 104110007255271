import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useToggle } from '@react-hookz/web';
import { useNavigate, useOutletContext } from 'react-router-dom';

import ModalConfirmation from '../../../ModalConfirmation';
import DetailedSheetCompanyForm from '../../../detailed-sheets/DetailedSheetCompanyForm';
import DetailedSheetDescriptionsForm from '../../../detailed-sheets/DetailedSheetDescriptionsForm';
import DetailedSheetImagesForm from '../../../detailed-sheets/DetailedSheetImagesForm';
import DetailedSheetInfosForm from '../../../detailed-sheets/DetailedSheetInfosForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import SaveUserDetailedSheetDto from '../../../../dto/detailed-sheets/in/save-user-detailed-sheet.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import EditionDto from '../../../../dto/editions/out/edition.dto';
import SaveFileDto from '../../../../dto/files/in/save-file.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import useSaveUserDetailedSheet from '../../../../hooks/detailed-sheets/save-user-detailed-sheet.hook';
import DetailedSheetFieldValuesInterface from '../../../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import MapDetailedSheetImageToSaveFileDtoService from '../../../../services/detailed-sheets/map-detailed-sheet-image-to-save-file-dto.service';
import UploadDetailedSheetImagesService from '../../../../services/detailed-sheets/upload-detailed-sheet-images.service';
import GetProfileCompanyNameService from '../../../../services/profiles/get-profile-compamy-name.service';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

const mapDetailedSheetImageToSaveFileDtoService =
  new MapDetailedSheetImageToSaveFileDtoService();

interface OutletContext {
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  edition: EditionDto;
  profile: ProfileDto;
}

const EditDetailedSheetPage: FC = () => {
  const navigate = useNavigate();
  const [uploading, toggleUploading] = useToggle(false);
  const [modalOpened, toggleModal] = useToggle(false);

  const { edition, detailedSheet, profile, booking } =
    useOutletContext<OutletContext>();

  const { mutateAsync: saveUserDetailedSheet } = useSaveUserDetailedSheet();

  const formMethods = useForm<DetailedSheetFieldValuesInterface>({
    defaultValues: {
      companyName: getProfileCompanyNameService.get(profile),
      address: profile.address,
      city: profile.city,
      province: profile.province,
      postalCode: profile.postalCode,
      email: '',
      phoneNumber: '',
      phoneNumberExt: '',
      isRbqMember: `${!!profile.rbqMemberNumber}`,
      rbqMemberNumber: profile.rbqMemberNumber ?? '',
      websiteUrl: '',
      instagramUrl: '',
      facebookUrl: '',
      linkedinUrl: '',
      websiteDescription: '',
      printDescription: '',
      tags: '',
      logo: undefined,
      image: undefined,
    },
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      toggleUploading(true);

      const { logo, image, ...fieldValues } = getValues();

      const uploads: [File, SaveFileDto][] = [];

      let [saveLogoDto, saveImageDto] = [
        detailedSheet.logo,
        detailedSheet.image,
      ].map((detailedSheetFile) =>
        detailedSheetFile
          ? plainToClass(SaveFileDto, {
              id: detailedSheetFile.id,
              key: detailedSheetFile.key,
              name: detailedSheetFile.name,
              extension: detailedSheetFile.extension,
              contentType: detailedSheetFile.contentType,
              size: detailedSheetFile.size,
              originalName: detailedSheetFile.originalName,
            })
          : undefined,
      );

      [
        { file: logo, name: 'logo' },
        { file: image, name: 'image' },
      ].forEach(({ file, name }) => {
        const isDirty = getFieldState(name as 'logo' | 'image').isDirty;

        if (file && isDirty) {
          const saveFileDto = mapDetailedSheetImageToSaveFileDtoService.map(
            edition,
            profile,
            file,
            name,
          );

          let dto: SaveFileDto;

          name === 'logo'
            ? (dto = saveLogoDto = saveFileDto)
            : (dto = saveImageDto = saveFileDto);

          uploads.push([file, dto]);
        }
      });

      if (uploads.length) {
        await new UploadDetailedSheetImagesService().upload(uploads);
      }

      const dto = plainToClass(SaveUserDetailedSheetDto, {
        id: detailedSheet.id,
        companyName: fieldValues.companyName.trim(),
        address: fieldValues.address,
        city: fieldValues.city,
        province: fieldValues.province,
        postalCode: fieldValues.postalCode,
        email: fieldValues.email?.trim(),
        phoneNumber: fieldValues.phoneNumber?.trim(),
        phoneNumberExt: fieldValues.phoneNumberExt,
        rbqMemberNumber: fieldValues.rbqMemberNumber,
        websiteUrl: fieldValues.websiteUrl,
        instagramUrl: fieldValues.instagramUrl,
        facebookUrl: fieldValues.facebookUrl,
        linkedinUrl: fieldValues.linkedinUrl,
        websiteDescription: fieldValues.websiteDescription.trim(),
        printDescription: fieldValues.printDescription.trim(),
        tags: fieldValues.tags.trim(),
        logo: plainToClass(SaveFileDto, {
          ...saveLogoDto,
          id: detailedSheet.logo?.id,
        }),
        image:
          image &&
          plainToClass(SaveFileDto, {
            ...saveImageDto,
            id: detailedSheet.image?.id,
          }),
      });

      await saveUserDetailedSheet(dto);

      toast.success('Enregistré avec succès');

      navigate(PATHS.exhibitors.path);
    } catch (error) {
      toast.error();
    } finally {
      toggleModal(false);
      toggleUploading(false);
    }
  }, [
    detailedSheet,
    edition,
    profile,
    getFieldState,
    getValues,
    navigate,
    saveUserDetailedSheet,
    toggleModal,
    toggleUploading,
  ]);

  const onSubmit: SubmitHandler<DetailedSheetFieldValuesInterface> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <SectionHeader>
          <Typography variant="h1" gutterBottom>
            Fiche exposant
          </Typography>
        </SectionHeader>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Votre entreprise
          </Typography>

          <DetailedSheetCompanyForm
            control={control}
            booking={booking}
            profile={profile}
            setValue={setValue}
          />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Votre présence en ligne
          </Typography>

          <DetailedSheetInfosForm control={control} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Descriptions
          </Typography>

          <DetailedSheetDescriptionsForm control={control} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Images
          </Typography>

          <DetailedSheetImagesForm control={control} />
        </Section>

        <Button
          disabled={isSubmitting || uploading}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={(isSubmitting || uploading) && <ButtonLoadingIcon />}
        >
          Enregistrer et envoyer
        </Button>

        <ModalConfirmation
          loading={uploading}
          loadingText="Transfert du formulaire et des images en cours. Veuillez patienter..."
          opened={modalOpened}
          title="Envoi de la fiche exposant"
          onSubmit={handleSave}
          onClose={(): void => toggleModal(false)}
        />
      </form>
    </FormProvider>
  );
};

export default EditDetailedSheetPage;
