import DoorTypesEnum from '../../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../../enums/assemblies/handling-equipments.enum';

class SaveAssemblyDto {
  firstName!: string;
  lastName!: string;
  email!: string;
  phoneNumber!: string;
  deliveryDate!: string;
  deliveryTime!: string;
  handlingEquipment!: HandlingEquipmentsEnum;
  doorType!: DoorTypesEnum;
  doorNumber!: number;
  needsNotes?: string;
  disassemblyDate!: string;
}

export default SaveAssemblyDto;
