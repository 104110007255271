import DetailedSheetStatusesEnum from '../../../enums/detailed-sheets/detailed-sheet-statuses.enum';
import ProvincesEnum from '../../../enums/provinces.enum';
import SaveFileDto from '../../files/in/save-file.dto';

class SaveUserDetailedSheetDto {
  id!: string;
  companyName!: string;
  address?: string;
  city?: string;
  province?: ProvincesEnum;
  postalCode?: string;
  email?: string;
  phoneNumber?: string;
  phoneNumberExt?: string;
  rbqMemberNumber?: string;
  websiteUrl?: string;
  instagramUrl?: string;
  facebookUrl?: string;
  linkedinUrl?: string;
  websiteDescription!: string;
  printDescription!: string;
  tags!: string;
  logo!: SaveFileDto;
  image?: SaveFileDto;
  status!: DetailedSheetStatusesEnum;
}

export default SaveUserDetailedSheetDto;
