import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { Control } from 'react-hook-form';

import CharacterCounter from '../forms/CharacterCounter';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import DetailedSheetFieldValuesInterface from '../../interfaces/detailed-sheets/detailed-sheet-field-values.interface';
import { validateLength, validateRequired } from '../../utils/validations';

interface Props {
  control: Control<DetailedSheetFieldValuesInterface>;
}

const AdminDetailedSheetDescriptionsForm: FC<Props> = ({ control }) => {
  return (
    <FormStack>
      <FormGrid>
        <Grid item xs={12} sm={12}>
          <TextField
            control={control}
            label="Texte descriptif complet, destiné à votre fiche en ligne (200 caractères max)"
            name="websiteDescription"
            rules={validateLength({ max: 200, required: true })}
            fullWidth
            multiline
          />

          <CharacterCounter
            control={control}
            name="websiteDescription"
            max={200}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            control={control}
            label="Texte descriptif destiné à votre fiche dans le magazine Expo habitat (75 caractères max)"
            name="printDescription"
            rules={validateLength({ max: 75, required: true })}
            fullWidth
            multiline
          />

          <CharacterCounter
            control={control}
            name="printDescription"
            max={75}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            label={
              <>
                {'Mots-clés'}
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Inscrivez ici jusqu’à 5 mots-clés, séparés par des points
                  virgules, qui décrivent vos produits et services (ex.:
                  rénovation; toiture; design d’intérieur; bois; finition;
                  maison neuve; etc.). Le tout ne s’affichera que sur la version
                  web de la fiche-exposant.
                </Typography>
              </>
            }
            name="tags"
            rules={validateRequired()}
            fullWidth
          />
        </Grid>
      </FormGrid>
    </FormStack>
  );
};

export default AdminDetailedSheetDescriptionsForm;
