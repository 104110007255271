import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { EditorState } from 'draft-js';
import { ReactElement, useCallback, useRef, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import theme from '../../styles/theme/index';

interface Props {
  label?: string;
}

const RichTextArea = <T extends FieldValues>({
  control,
  label,
  name,
  rules,
  ...props
}: Props & UseControllerProps<T> & EditorProps): ReactElement => {
  const [focused, setFocus] = useState(false);
  const editorRef = useRef<Editor>(null);

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name, rules });

  const handleEditorStateChange = useCallback(
    (editorState: EditorState) => {
      onChange(editorState as PathValue<T, Path<T>>);
    },
    [onChange],
  );

  const handleFocus = useCallback(() => {
    if (!focused) {
      setFocus(true);

      editorRef.current?.focusEditor();
    }
  }, [focused, setFocus]);

  const handleBlur = useCallback(() => {
    if (focused) {
      setFocus(false);
    }
  }, [focused, setFocus]);

  return (
    <FormControl
      error={!!error}
      focused={focused}
      sx={{
        mb: 1,
        '& .rich-text-area-wrapper .rdw-link-modal': {
          height: 'auto',
        },
      }}
      fullWidth
    >
      {label && <FormLabel onClick={handleFocus}>{label}</FormLabel>}

      <Editor
        {...props}
        ref={editorRef}
        editorState={value}
        onEditorStateChange={handleEditorStateChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        toolbar={{
          options: ['inline', 'list', 'link', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
        }}
        wrapperStyle={{
          marginTop: theme.spacing(1),
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: '3px',
        }}
        editorStyle={{
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(1),
        }}
        wrapperClassName="rich-text-area-wrapper"
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default RichTextArea;
