import axios from 'axios';
import { plainToClass } from 'class-transformer';

import GetSignedUrlService from '../files/get-signed-urls.service';
import GetSignedUrlDto from '../../dto/files/in/get-signed-url.dto';
import SaveFileDto from '../../dto/files/in/save-file.dto';

class UploadDetailedSheetImagesService {
  async upload(uploads: [File, SaveFileDto][]): Promise<void> {
    const filesAndGetSignedUrlDtos: [File, GetSignedUrlDto][] = [];

    for (const [file, saveFileDto] of uploads) {
      const getSignedUrlDto = plainToClass(GetSignedUrlDto, {
        name: saveFileDto.key,
        contentType: saveFileDto.contentType,
      });

      filesAndGetSignedUrlDtos.push([file, getSignedUrlDto]);
    }

    const getSignedUrlDtos = filesAndGetSignedUrlDtos.map(
      (filesAndGetSignedUrlDto) => filesAndGetSignedUrlDto[1],
    );

    const signedUrls = await new GetSignedUrlService().get(getSignedUrlDtos);

    await Promise.all(
      signedUrls.map(async (signedUrl, index) => {
        const [file] = filesAndGetSignedUrlDtos[index];

        return axios.put(signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
      }),
    );
  }
}

export default UploadDetailedSheetImagesService;
