import { plainToClass } from 'class-transformer';

import EditionDto from '../../dto/editions/out/edition.dto';
import SaveFileDto from '../../dto/files/in/save-file.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import GetFileExtensionService from '../files/get-file-extension.service';

class MapDetailedSheetImageToSaveFileDtoService {
  map(
    edition: EditionDto,
    profile: ProfileDto,
    file: File,
    fileName: string,
  ): SaveFileDto {
    const originalExtenion = new GetFileExtensionService().get(file);

    const fileFullName = `${fileName}.${originalExtenion}`;

    return plainToClass(SaveFileDto, {
      key: `images/original/${edition.name}/profiles/${profile.id}/${fileFullName}`,
      name: fileFullName,
      extension: originalExtenion,
      contentType: file.type,
      size: file.size,
      originalName: file.name,
      hasSmallVariant: false,
    });
  }
}

export default MapDetailedSheetImageToSaveFileDtoService;
