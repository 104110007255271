import { convertToRaw, EditorState } from 'draft-js';
import {
  FieldValues,
  RegisterOptions,
  UseFormGetValues,
} from 'react-hook-form';

import { toast } from './toast';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const onInvalidSubmit = (): void =>
  toast.error('Veuillez vérifier et corriger les informations saisies');

export const validateApchqMemberNumber = (
  condition = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value: string): string | boolean => {
    const formattedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');

    if (condition && !/^\d{4,}$/.test(formattedValue)) return 'Format invalide';

    return true;
  },
});

export const validateRbqMemberNumber = (
  condition = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value: string): string | boolean => {
    const formattedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, '');

    if (condition && !/^\d{10}$/.test(formattedValue)) return 'Format invalide';

    return true;
  },
});

export const validateEmailFormat = (): Pick<RegisterOptions, 'pattern'> => ({
  pattern: {
    value: EMAIL_REGEX,
    message: 'Format invalide',
  },
});

export const validateEmail = (
  allowNull = false,
): Pick<RegisterOptions, 'validate'> => ({
  validate(value: string): string | boolean {
    const trimmedValue = value?.trim();

    if (allowNull && !trimmedValue) return true;

    if (!EMAIL_REGEX.test(trimmedValue)) {
      return 'Format invalide';
    }

    return true;
  },
});

export const validatePasswordMatch = <T extends FieldValues>(
  getValues: UseFormGetValues<T>,
): Pick<RegisterOptions, 'validate'> => ({
  validate(value: string): string | boolean {
    const { password } = getValues();

    return value !== password ? 'Le mot de passe est incorrecte' : true;
  },
});

export const validatePhoneNumberFormat = (
  allowEmpty = false,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (phoneNumber = ''): string | boolean => {
    const trimmedPhoneNumber = phoneNumber?.trim();

    if (!trimmedPhoneNumber && allowEmpty) return true;

    const formattedPhoneNumber = trimmedPhoneNumber.replace(/[^0-9]/g, '');

    return formattedPhoneNumber.length !== 10 ? 'Format invalide' : true;
  },
});

export const validatePostalCodeFormat = (
  allowEmpty = false,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (postalCode = ''): string | boolean => {
    if (allowEmpty && !postalCode?.trim()) return true;

    const formattedPostalCode = postalCode
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '');

    const invalid = !/[A-Z]\d[A-Z]\d[A-Z]\d/.test(formattedPostalCode);

    return invalid ? 'Format invalide' : true;
  },
});

export const validateArrayRequired = (
  minSize = 1,
  maxSize: number | undefined = undefined,
  condition = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value): string | boolean => {
    const size = value?.length ?? 0;

    const invalid = size < minSize || size > (maxSize ?? size);

    if (condition && invalid) return 'Obligatoire';

    return true;
  },
});

export const validateRequired = (
  condition = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value): string | boolean => {
    if (condition && !value) return 'Obligatoire';

    return true;
  },
});

export const validateRichText = (
  allowNull = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value: EditorState): string | boolean => {
    const context = value.getCurrentContent();

    if (!allowNull && !context.hasText()) return 'Obligatoire';

    const jsonContext = JSON.stringify(convertToRaw(context));

    if (jsonContext.length > 60000) return 'Maximum de caractères dépassé';

    return true;
  },
});

export const validateWebsite = (
  allowNull = true,
): Pick<RegisterOptions, 'validate'> => ({
  validate: (value: string): string | boolean => {
    const trimmedValue = value.trim();

    if (
      (!allowNull || !!trimmedValue) &&
      !/^https?:\/\/(?:[a-z0-9-]{1,63}\.)+.{2,}$/gi.test(value)
    ) {
      return 'Format invalide';
    }
    return true;
  },
});

export const validateLength = ({
  min = 0,
  max = Infinity,
  required = false,
}: {
  min?: number;
  max?: number;
  required?: boolean;
}): Pick<RegisterOptions, 'validate'> => ({
  validate: (value: string | null): string | boolean => {
    const trimmedValue = value?.trim() ?? '';

    const length = trimmedValue.length;

    if (required && length <= 0) return 'Obligatoire';
    if (length < min) return 'Minimum de caractères non atteint';
    if (length > max) return 'Maximum de caractères dépassé';

    return true;
  },
});
