import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';

interface Props {
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetDescriptionsSection: FC<Props> = ({ detailedSheet }) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Descriptions
      </Typography>

      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Texte descriptif complet, destiné à votre fiche en ligne
            </ReadonlyFieldLabel>

            <Typography>{detailedSheet.websiteDescription}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>
              Texte descriptif destiné à votre fiche dans le magazine Expo
              habitat
            </ReadonlyFieldLabel>

            <Typography>{detailedSheet.printDescription}</Typography>
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel>Mots-clés</ReadonlyFieldLabel>

            <Typography>{detailedSheet.tags}</Typography>
          </Grid>
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetDescriptionsSection;
