import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import DetailedSheetAddress from './DetailedSheetAddress';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Section from '../layouts/Section';
import BookingDto from '../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';

interface Props {
  profile: ProfileDto;
  booking: BookingDto;
  detailedSheet: DetailedSheetDto;
}

const DetailedSheetCompanySection: FC<Props> = ({
  booking,
  detailedSheet,
  profile,
}) => {
  return (
    <Section>
      <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
        Votre entreprise
      </Typography>

      <Typography variant="subtitle1">{detailedSheet.companyName}</Typography>

      <DetailedSheetAddress detailedSheet={detailedSheet} />

      <FormStack>
        <FormGrid>
          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Catégorie(s)</ReadonlyFieldLabel>

            {profile.profileCategories.map((profileCategory) => (
              <Typography key={profileCategory.id}>
                {profileCategory.category.name}
              </Typography>
            ))}
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Emplacement officiel</ReadonlyFieldLabel>

            <Typography>{booking?.mainLocation ?? 'En traitement'}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Courriel</ReadonlyFieldLabel>

            <Typography>{detailedSheet.email}</Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <ReadonlyFieldLabel>Téléphone</ReadonlyFieldLabel>

            <Typography>
              {detailedSheet.phoneNumber}
              {detailedSheet.phoneNumberExt && (
                <> Ext: {detailedSheet.phoneNumberExt}</>
              )}
            </Typography>
          </Grid>

          {!!detailedSheet.rbqMemberNumber && (
            <Grid item xs={12} md={6}>
              <ReadonlyFieldLabel>Numéro de licence RBQ</ReadonlyFieldLabel>

              <Typography>{detailedSheet.rbqMemberNumber}</Typography>
            </Grid>
          )}
        </FormGrid>
      </FormStack>
    </Section>
  );
};

export default DetailedSheetCompanySection;
